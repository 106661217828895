<!--
 * @Author: mulingyuer
 * @Date: 2021-09-16 16:19:09
 * @LastEditTime: 2021-09-30 15:22:34
 * @LastEditors: aleaner
 * @Description: 数据卡片
 * @FilePath: \gov\components\bid-data\DataItem.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="data-item">
    <div class="head">
      <h2 class="title">{{ title }}</h2>
      <div class="filter">
        <!-- <el-tag>今日</el-tag> -->
     <slot name="time"></slot>
      </div>
    </div>
    <div class="body-box">
      <div class="content-show">
        <div class="strong">{{ data.crr_count || 0 }}</div>
        <span>{{ unit }}</span>
      </div>
      <div class="filter-box" v-if="showFilterBox">
        <div class="time-box">昨日: {{ data.last_count || 0 }}</div>
        <div class="chain-ratio">
          环比：
          <div class="number" :style="{
            color:data.status ? '#FF2647' : '#13CE66'
          }">
            {{ data.status ? '+' : '-' }}{{ data.diff_count || 0 }}%
          </div>
          <img  v-if="data.status" src="@/modules/consult/assets/images/up.png" class="icon"></img>
          <img v-else src="@/modules/consult/assets/images/down.png" class="icon"></img>
        </div>
      </div>
    </div>
    <div class="line"></div>
    <div class="footer">
      <div class="footer-left">总数：</div>
      <div class="footer-right">
        {{ data.total_count || 0 }}<span>{{ unit }}</span>
      </div>
    </div>
    <div v-if="data.type === 3" class="button-box" @click="goChecking">前往审核</div>
  </div>
</template>

<script>
import MyDatePicker from '@/modules/gov/components/bid-data/MyDatePicker.vue'

export default {
  props: {
    title: {
      type: String,
      default: '标题',
    },
    // 数量的单位
    unit: {
      type: String,
      default: '次',
    },
    data: {
      type: Object,
      default: () => {},
    },
    showFilterBox:{
      type: Boolean,
      default: true,
    }
  },
  data() {
    return {
     
    }
  },
  methods:{
    refshData(e) {
      console.log(this.data,e)
      this.$emit('refshData',this.data)
    },
  },
  components: {MyDatePicker},
}
</script>

<style lang="scss" scoped>
.data-item {
  padding: 20px;
  font-family: 'PingFang SC';
  background-color: #fff;
  min-height:200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .head {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    .title {
      color: '1a1a1a';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }

    .filter {
    }
  }
  .body-box {
    margin-top: 12px;
    .content-show {
      display: flex;
      align-items: center;
      .strong {
        color: #1a1a1a;

        font-size: 24px;
        font-weight: 500;
      }
      span {
        margin-left: 8px;
        color: #808080;
        font-size: 14px;
      }
    }

    .filter-box {
      margin-top: 8px;
      color: #1a1a1a;
      font-size: 12px;
      font-weight: 500;
      display: flex;
      align-items: center;
      min-height: 20px;
      .time-box {
      }

      .chain-ratio {
        margin-left: 24px;
        display: flex;
        align-items: center;
        .number {
          color: #13ce66;
          font-size: 14px;
          font-weight: 700;
        }

        .icon {
          margin-left: 5px;
          width: 12px;
          height: 12px;
        }
      }
    }
  }
  .line {
    width: 100%;
    background-color: rgba(#000, 0.1);
    height: 1px;
    margin: 16px 0;
  }
  .footer {
    color: #1a1a1a;
    font-size: 12px;
    font-weight: 500;
    display: flex;
    align-items: center;
    justify-content: space-between;
    .footer-left {
    }

    .footer-right {
      span {
        color: #808080;
        font-size: 11rpx;
        margin-left: 4px;
      }
    }
  }
}

.button-box {
    background-color: rgba(#1a75ff, 0.05);
    color: #1a75ff;
    font-weight: 500;
    font-size: 14rpx;
    height: 47px;
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
  }
</style>
