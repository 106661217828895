<!--
 * @Author: mulingyuer
 * @Date: 2021-09-16 16:19:09
 * @LastEditTime: 2021-09-30 15:22:34
 * @LastEditors: aleaner
 * @Description: 数据卡片
 * @FilePath: \gov\components\bid-data\DataItem.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="data-item">
    <div class="head">
      <h2 class="title">{{ title }}</h2>
      <div class="filter">
        <slot name="time"></slot>
      </div>
    </div>
    <div class="body-box">
      <template v-if="data.data_list && data.data_list.length">
        <div class="rank-item" v-for="(item, i) in data.data_list" :key="i">
          <div class="left">
            <div
              class="number"
              :style="{
                color: i === 0 ? '#FF2647' : i === 1 ? '#FFC635' : '#1A75FF',
              }"
            >
              {{ i + 1 }}
            </div>
            <div class="name">{{ item.name }}</div>
            <div class="company">{{ item.company_name }}</div>
          </div>
          <div class="right">{{ item.point_count }}次</div>
        </div>
      </template>
      <el-empty style="padding:0" :image-size="60" v-else description="暂无数据"></el-empty>
    </div>
  </div>
</template>

<script>
import MyDatePicker from '@/modules/gov/components/bid-data/MyDatePicker.vue'

export default {
  props: {
    title: {
      type: String,
      default: '标题',
    },
    // 数量的单位
    unit: {
      type: String,
      default: '次',
    },
    data: {
      type: Object,
      default: () => {},
    },
    showFilterBox: {
      type: Boolean,
      default: true,
    },
  },
  data() {
    return {}
  },
  methods: {
    refshData(e) {
      console.log(this.data, e)
      this.$emit('refshData', this.data)
    },
  },
  components: { MyDatePicker },
}
</script>

<style lang="scss" scoped>
.data-item {
  padding: 20px 20px;
  font-family: 'PingFang SC';
  background-color: #fff;
  min-height: 200px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  .head {
    display: flex;
    // align-items: center;
    justify-content: space-between;
    .title {
      color: '1a1a1a';
      font-size: 16px;
      font-style: normal;
      font-weight: 500;
    }

    .filter {
    }
  }
  .body-box {
    margin-top: 12px;
    .rank-item {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .left {
        flex: 1;
        display: flex;

        align-items: center;
        .number {
          font-size: 16px;
          font-style: normal;
          font-weight: 500;
        }

        .name {
          margin-left: 12px;
          font-size: 14px;
          font-style: normal;
          font-weight: 500;
          color: #1a1a1a;
        }

        .company {
          margin-left: 24px;
          font-size: 14px;
          font-style: normal;
          font-weight: 400;
          color: #cbcbcb;
        }
      }

      .right {
        color: #ff7b1b;
        font-size: 14px;
        font-style: normal;
        font-weight: 500;
      }
    }
    .rank-item + .rank-item {
      margin-top: 20px;
    }
  }
}
</style>
