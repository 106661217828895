<!--
 * @Author: mulingyuer
 * @Date: 2021-09-02 17:51:44
 * @LastEditTime: 2021-09-02 18:20:48
 * @LastEditors: mulingyuer
 * @Description: 设置
 * @FilePath: \saas-admin-vue\src\modules\online-classroom\views\settings.vue
 * 怎么可能会有bug！！！
-->
<template>
  <div class="consult-stats">
    <div class="base-card-list" v-loading="pageLoading">
      <el-row :gutter="10">
        <!-- <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CommonCard
            :title="statsList1.data.label"
            :data="statsList1.data"
            :unit="statsList1.data.unit"
          >
            <template #time>
              <MyDatePicker
                :isInline="false"
                v-model="statsList1.time"
                @change="changeTimePicker(1)"
                ref="MyDatePicker1"
              />
            </template>
          </CommonCard>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CommonCard
            :title="statsList1.data.label"
            :data="statsList2.data"
            :unit="statsList2.data.unit"
          >
            <template #time>
              <MyDatePicker
                :isInline="false"
                v-model="statsList2.time"
                @change="changeTimePicker(2)"
                ref="MyDatePicker2"
              />
            </template>
          </CommonCard>
        </el-col> -->
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CommonCard
            :title="statsList3.data.label"
            :data="statsList3.data"
            :unit="statsList3.data.unit"
          >
            <template #time>
              <MyDatePicker
                @change="changeTimePicker(3)"
                :isInline="false"
                v-model="statsList3.time"
                ref="MyDatePicker3"
              />
            </template>
          </CommonCard>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CommonCard
            :title="statsList4.data.label"
            :data="statsList4.data"
            :unit="statsList4.data.unit"
          >
            <template #time>
              <MyDatePicker
                @change="changeTimePicker(4)"
                :isInline="false"
                v-model="statsList4.time"
                ref="MyDatePicker4"
              />
            </template>
          </CommonCard>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CommonCard
            :title="statsList5.data.label"
            :data="statsList5.data"
            :unit="statsList5.data.unit"
          >
            <template #time>
              <MyDatePicker
                @change="changeTimePicker(5)"
                :isInline="false"
                v-model="statsList5.time"
                ref="MyDatePicker5"
              />
            </template>
          </CommonCard>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CommonCard
            :title="statsList6.data.label"
            :data="statsList6.data"
            :unit="statsList6.data.unit"
          >
            <template #time>
              <MyDatePicker
                @change="changeTimePicker(6)"
                :isInline="false"
                v-model="statsList6.time"
                ref="MyDatePicker6"
              />
            </template>
          </CommonCard>
        </el-col>
      </el-row>
      <!-- <el-row :gutter="10">
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CommonCard
            :title="statsList5.data.label"
            :data="statsList5.data"
            :unit="statsList5.data.unit"
          >
            <template #time>
              <MyDatePicker
                @change="changeTimePicker(5)"
                :isInline="false"
                v-model="statsList5.time"
                ref="MyDatePicker5"
              />
            </template>
          </CommonCard>
        </el-col>
        <el-col :xs="12" :sm="12" :md="12" :lg="12" :xl="6">
          <CommonCard
            :title="statsList6.data.label"
            :data="statsList6.data"
            :unit="statsList6.data.unit"
          >
            <template #time>
              <MyDatePicker
                @change="changeTimePicker(6)"
                :isInline="false"
                v-model="statsList6.time"
                ref="MyDatePicker6"
              />
            </template>
          </CommonCard>
        </el-col>
      </el-row> -->
      <el-row :gutter="10">
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
          <CommonRankCard
            :title="statsList7.data.label"
            :data="statsList7.data"
            :unit="statsList7.data.unit"
          >
            <template #time>
              <MyDatePicker
                @change="changeTimePicker(7)"
                :isInline="false"
                v-model="statsList7.time"
                ref="MyDatePicker7"
              />
            </template>
          </CommonRankCard>
        </el-col>
        <el-col :xs="24" :sm="24" :md="24" :lg="24" :xl="12">
          <CommonRankCard
            :title="statsList8.data.label"
            :data="statsList8.data"
            :unit="statsList8.data.unit"
          >
            <template #time>
              <MyDatePicker
                @change="changeTimePicker(8)"
                :isInline="false"
                v-model="statsList8.time"
                ref="MyDatePicker8"
              />
            </template>
          </CommonRankCard>
        </el-col>
      </el-row>
    </div>
  </div>
</template>

<script>
// 通用卡片
import CommonCard from '@/modules/employ/components/employ-data-stats/CommonCard.vue'
import CommonRankCard from '@/modules/employ/components/employ-data-stats/CommonRankCard.vue'
import { boards } from '@/modules/employ/api/employ-data-stats.js'
import MyDatePicker from '@/modules/gov/components/bid-data/MyDatePicker.vue'
// 先隐藏第一个接口和第二个接口
export default {
  data() {
    return {
      statsList1: {
        key: 'collect_career',
        data: {},
        time: [-1, -1],
      },
      statsList2: {
        key: 'delivers',
        data: {},
        time: [-1, -1],
      },
      statsList3: {
        key: 'careers',
        data: {},
        time: [-1, -1],
      },
      statsList4: {
        key: 'companies',
        data: {},
        time: [-1, -1],
      },
      statsList5: {
        key: 'delivering',
        data: {},
        time: [-1, -1],
      },
      statsList6: {
        key: 'delivered',
        data: {},
        time: [-1, -1],
      },
      statsList7: {
        key: 'max_collect_career',
        data: {},
        time: [-1, -1],
      },
      statsList8: {
        key: 'max_deliver_career',
        data: {},
        time: [-1, -1],
      },
      chartList: [],
      pageLoading: false,
    }
  },
  methods: {
    changeTimePicker(i) {
      console.log(i)
      boards({
        data_key: this[`statsList${i}`].key,
        start_time: this[`statsList${i}`].time[0],
        end_time: this[`statsList${i}`].time[1],
      }).then((res) => {
        this[`statsList${i}`].data = res.data
      })
    },
    refshAllList() {
      this.pageLoading = true
      // let p1 = boards({
      //   data_key: this.statsList1.key,
      //   start_time: this.statsList1.time[0],
      //   end_time: this.statsList1.time[1],
      // }).then((res) => {
      //   this.statsList1.data = res.data
      // })
      // let p2 = boards({
      //   data_key: this.statsList2.key,
      //   start_time: this.statsList2.time[0],
      //   end_time: this.statsList2.time[1],
      // }).then((res) => {
      //   this.statsList2.data = res.data
      // })
      let p3 = boards({
        data_key: this.statsList3.key,
        start_time: this.statsList3.time[0],
        end_time: this.statsList3.time[1],
      }).then((res) => {
        this.statsList3.data = res.data
      })
      let p4 = boards({
        data_key: this.statsList4.key,
        start_time: this.statsList4.time[0],
        end_time: this.statsList4.time[1],
      }).then((res) => {
        this.statsList4.data = res.data
      })
      let p5 = boards({
        data_key: this.statsList5.key,
        start_time: this.statsList5.time[0],
        end_time: this.statsList5.time[1],
      }).then((res) => {
        this.statsList5.data = res.data
      })
      let p6 = boards({
        data_key: this.statsList6.key,
        start_time: this.statsList6.time[0],
        end_time: this.statsList6.time[1],
      }).then((res) => {
        this.statsList6.data = res.data
      })
      let p7 = boards({
        data_key: this.statsList7.key,
        start_time: this.statsList7.time[0],
        end_time: this.statsList7.time[1],
      }).then((res) => {
        this.statsList7.data = res.data
      })
      let p8 = boards({
        data_key: this.statsList8.key,
        start_time: this.statsList8.time[0],
        end_time: this.statsList8.time[1],
      }).then((res) => {
        this.statsList8.data = res.data
      })
      // let promiseAll = [p1, p2, p3, p4, p5, p6, p7, p8]
      let promiseAll = [ p3, p4, p5, p6, p7, p8]
      Promise.all(promiseAll)
        .then((res) => {
          this.pageLoading = false
          console.log(res, 'res')
        })
        .catch((err) => {
          this.pageLoading = false
        })
    },
  },
  created() {
    this.$nextTick(() => {
      //获取时间默认值
      // this.$refs['MyDatePicker1'].updateTime()
      // this.$refs['MyDatePicker2'].updateTime()
      this.$refs['MyDatePicker3'].updateTime()
      this.$refs['MyDatePicker4'].updateTime()
      this.$refs['MyDatePicker5'].updateTime()
      this.$refs['MyDatePicker6'].updateTime()
      this.$refs['MyDatePicker7'].updateTime()
      this.$refs['MyDatePicker8'].updateTime()
      //发起请求
      this.refshAllList()
    })
  },
  components: {
    CommonCard,
    MyDatePicker,
    CommonRankCard,
  },
}
</script>

<style lang="scss" scoped>
.consult-stats {
  .base-card-list {
    .el-col {
      margin-bottom: 10px;
    }
  }
}
</style>
