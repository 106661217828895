import api from '@/base/utils/request'

export const boards = (data) => {
  return api({
    url: '/admin/employ/dataStats/stats',
    method: 'post',
    data,
    notCancel: true,
  })
}
